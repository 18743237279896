.view-whats-new {
    @extend .container;

    .views-row {
        @extend .row;

        .views-field-field-image {
            display: none;

            img {
                @extend .img-responsive;
                margin: 0 auto;
            }
        }

        .views-field-title {
            @extend .col-xs-12;

            .field-content {
                display: block;
                font-size: round($font-size-base * 1.5);
                font-weight: 400;
                margin: rem(40) 0 rem(20) 0;
                padding-bottom: rem(10);
                border-bottom: 1px solid #aaa;

                a {
                    color: $gray-dark;
                }
            }
        }

        .views-field-body {
            @extend .col-xs-12;

            .field-content {

                p {
                    font-weight: 400;
                    font-size: round($font-size-base * 1.1);
                }
            }
        }

        &.has-image-1 {
            .views-field-field-image {
                display: block;

                @extend .col-xs-12;
                @extend .col-sm-4;
                @extend .col-md-3;
                @extend .col-lg-2;
            }

            .views-field-body {
                @extend .col-sm-8;
                @extend .col-md-9;
                @extend .col-lg-10;
            }
        }
    }
}