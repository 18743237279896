#views-exposed-form-stockists-list-block{
  #edit-combine-wrapper{
    width:100%;
    input[type="text"]{
      @extend .form-control;
      @include font-family-1(300,14); 
      resize:none;
      outline:none;
      margin-bottom:rem(10);
    }
  } 
  .views-exposed-widgets{
    margin-bottom:0;
  }     
  .views-exposed-widget{
    padding:0;   
    width:100%; 
  }
  .form-submit{
    margin:0;
  }
  .ajax-progress-throbber {
    display:none;
  }     
  input[type="submit"]{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-block;
    @include font-family-1(300,14);
    text-transform:uppercase;
    color:#000;
    position:relative;
    top:rem(1);
    outline:none;
    margin-right:rem(5);
    -webkit-appearance:none;            
  }      
}