.fancy-slide {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    min-height: 200px;

    .text {
        padding-top: 30px;
        font-family: $font-family-headings;
        color: #fff;
        line-height: $font-size-base * 1.4;
        text-transform: uppercase;
        text-shadow: 4px 4px 8px #000;
        position: relative;
        z-index: 10;

        p,h1,h2 {
            font-size: $font-size-base * 1.2;

            strong {
                font-weight: normal;
                font-size: $font-size-base * 1.6;
                color: $brand-yellow;
            }
        }
    }

    .image {
        position: absolute;
        right: -40px;
        top: -5px;
        width: 60%;

        img {
            @extend .img-responsive;
        }
    }

    &.remove-shadow {
        .text {
            text-shadow: none;
        }
    }
}

.fancy-page {
    position: relative;
    top: -2px;

    .main.page {
        padding-top: 0;
    }

    .paragraphs-item-slideshow {
        padding-top: 0;
    }
}

@media (min-width: $screen-xs-min) {
    .fancy-slide {
        min-height: 250px;

        .text {
            padding-top: 70px;
            line-height: $font-size-base * 1.6;

            p,h1,h2 {
                font-size: $font-size-base * 1.4;

                strong {
                    font-size: $font-size-base * 1.8;
                }
            }
        }

        .image {
            right: -5%;
            top: 10px;
            max-width: 230px;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .fancy-slide {
        min-height: 300px;

        .text {
            padding-top: 90px;
            line-height: $font-size-base * 1.8;

            p,h1,h2 {
                font-size: $font-size-base * 1.6;

                strong {
                    font-size: $font-size-base * 2;
                }
            }
        }

        .image {
            top: 25px;
            right: 15%;
            max-width: 250px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .fancy-slide {
        min-height: 350px;

        .text {
            padding-top: 90px;
            line-height: $font-size-base * 2.2;

            p,h1,h2 {
                font-size: $font-size-base * 2;

                strong {
                    font-size: $font-size-base * 3;
                }
            }
        }

        .image {
            right: 10%;
            top: 10px;
            max-width: 320px;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .fancy-slide {
        min-height: 500px;

        .text {
            padding-top: 130px;
            line-height: $font-size-base * 3.2;

            p,h1,h2 {
                font-size: $font-size-base * 2.5;

                strong {
                    font-size: $font-size-base * 4;
                }
            }
        }

        .image {
            top: 20px;
            max-width: 430px;
            right: 10%;
        }
    }
}

@media (min-width: $screen-xl-min) {
    .fancy-slide {
        min-height: 600px;

        .text {
            padding-top: 170px;
            line-height: $font-size-base * 3.5;

            p,h1,h2 {
                font-size: $font-size-base * 3;

                strong {
                    font-size: $font-size-base * 4.5;
                }
            }
        }

        .image {
            top: 60px;
            max-width: 460px;
        }
    }
}

@media (min-width: $screen-xxl-min) {
    .fancy-slide {
        min-height: 800px;

        .text {
            padding-top: 230px;
            line-height: $font-size-base * 4.5;

            p,h1,h2 {
                font-size: $font-size-base * 3.8;

                strong {
                    font-size: $font-size-base * 5.5;
                }
            }
        }

        .image {
            top: 90px;
            max-width: 590px;
            right: -140px;
        }
    }
}