.field-name-field-accessories {
    @extend .row;

    > .field-items {
        > .field-item {
            @extend .col-sm-4;
            text-align: center;

            a {
                color: $text-color;
            }

            .commerce-product-title {
                font-size: rem(20);
                margin: rem(20) 0;

                .commerce-product-title-label {
                    display: none;
                }


            }

            img {
                @extend .img-responsive;
                @extend .img-thumbnail;
            }
        }
    }
}