#block-menu-menu-footer-menu-column-one{
  ul{
    @extend .clearfix;

    li{
      width:50%;
      float:left;
      margin: 2px 0;
    }
  }
}