.product-group,
.product{
  margin-bottom:rem(25);
  position:relative;
  padding:rem(20);
  img{
    min-width:90% !important;
    max-width:90% !important;
    height:auto !important;
    //display:block;
  }
  .overlay{
    display:none;
    position:absolute;
  }
  a{
    outline:none;
    display:block;
    text-align: center;

    &:hover{
      .overlay{
        background-image:url('../img/overlay-image.png');
        display:block;
        width:100%;
        text-align:center;
        color:#fff;
        font-size:rem(24);
      }
    }
  }
  h2{
    @include font-family-1(300,18);
    margin:0;
    padding:0;
    text-transform:uppercase;
    padding-top:rem(9);
    padding-bottom:rem(10);
    margin-bottom:rem(10);
    text-align:center;
    a{
      color:#000;
      text-decoration:none;
    }
  }
}

.product-list-row {
  text-align: center;

  .product,
  .product-group {
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .taxonomy-term-description {
    text-align: left;
  }
}

@media (min-width: $screen-sm-min) {
  .product-list-row {
    .product,
    .product-group {
      width: 24%;
    }
  }
}