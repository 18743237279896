.products-image{
  @include product-gradient;
  padding-top:rem(40);
  padding-bottom:rem(40);
  border-bottom: rem(1) solid #ddd;
}
#products-image{
  background-position:center center;
  background-repeat:no-repeat;
  width:100%;
}