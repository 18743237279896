.paragraphs-item-three-columns {
    margin-bottom: rem(30);

    .column-title {
        margin-bottom: rem(20);
        margin-top: 0;
    }

    > .row {
        > .col-sm-4 {
            margin-bottom: rem(30);
        }
    }
}

@media (min-width: $screen-sm-min) {
    .paragraphs-item-three-columns {
        > .row {
            > .col-sm-4 {
                margin-bottom: 0;
            }
        }
    }
}


.page-node-7695 {
    .paragraphs-item-three-columns {
        .paragraphs-item-image-group {
            .column-title {
                text-align: center;
                font-family: $font-family-open-sans;
                font-weight: 300;
                text-transform: uppercase;

                a {
                    color: #000;
                }
            }

            .image-wrapper {
                overflow: hidden;

                .field-type-image {
                    position: relative;
                    top: -80px;
                    margin-bottom: -100px;
                }
            }
        }
    }
}