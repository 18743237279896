#product-carousel{
  .carousel-inner{
    &>.item{
      &>a{
        &>img{
          margin-left:auto;
          margin-right:auto;
        }
      }
    }
  }  
  .carousel-control{
    &.left,&.right{
      background-image:none;
    }
  }
}