.paragraphs-item-full-width-image {

    padding: 0;

    &.white-background {
        background-color: #fff;
    }

    &.dark-grey-background {
        background-color: #333;
    }

    &.light-grey-background {
        background-color: #f6f6f6;
    }

    &.blue-gradient-background {
        @include product-gradient;
        border-bottom: 1px solid #dddddd;
    }

    > .content {
        min-height: 200px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    img {
        @extend .img-responsive;
    }
}

.field-name-field-background-style {
    display: none;
}

@media (min-width: $screen-xs-min) {
    .paragraphs-item-full-width-image {
        > .content {
            min-height: 250px;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .paragraphs-item-full-width-image {
        > .content {
            min-height: 300px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .paragraphs-item-full-width-image {
        > .content {
            min-height: 350px;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .paragraphs-item-full-width-image {
        > .content {
            min-height: 400px;
        }
    }
}