body{
  height:100%;
  margin-bottom:rem($footer-height + $footer-padding);
  font-weight:400;
  font-size: $em-base;
  position: static !important;

  &.admin-bar{
    .main{
      padding-top:rem($navbar-height - 32);
    }
  }
  &.not-logged-in{
    .navbar{
      //@extend .navbar-fixed-top;
    }
  }
  &.page-pictures{
    padding-top:0 !important;
  }

  &.front {
    margin-bottom: rem($footer-height);
  }
}

@media (min-width: $screen-md-min) {
  body {
    font-size: round($em-base * 0.9);
  }
}


.scrollToTop {
  width: 50px;
  height: 50px;
  line-height: 40px;
  text-align: center;
  background: #fc0;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom:15px;
  right: 15px;
  display: none;
  z-index: 2000;
  font-size: $font-size-base * 2;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 400ms;
  border-radius: 3px;

  &:hover {
    text-decoration: none;
    color: #fff;
    opacity: 0.9;
  }
}

@media (min-width: $screen-md-min) {
  .scrollToTop {
    bottom: 30px;
    right: 30px;
  }
}