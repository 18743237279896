.products-tabs{
  margin-top:rem(-41);
  &.loading{
    margin-top:0;
  }
  @media(max-width:768px){
    margin-top:rem(20);
  }
  ul{
    li{
      text-transform:uppercase;
      @media(max-width:768px){
        display:block;
        float:none;
        ul{
          &.dropdown-menu{
            a{
              border:none !important;
            }
          }
        }
        a{
          border-radius:0;
          border: 1px solid #ddd;
        }
        &:last-child{
          a{
            border-bottom: 1px solid #ddd !important;
          }
        }
        &.active{
          a{
            background-color:#ddd !important;
            color:#000 !important;
          }
        }
      }
      a{
        color:#555;
      }
    }
  }
  a{
    &.dropdown-toggle{
      color: #000;
      background-color: #ffcc00;
      border: 1px solid #ffcc00;
      border-bottom-color: transparent;
      cursor: default;
      &:hover,&:focus{
        color: #fff;
        background-color: darken(#ffcc00, 6.5%);
        border: 1px solid darken(#ffcc00, 6.5%);
        border-bottom-color: transparent;
        cursor: default;
      }
    }
  }
  .tab-content{
    padding-top:rem(15);
    padding-bottom:rem(15);
    .table{
      td{
        padding-left:0;
        padding-right:0;
      }
    }
    h3,h4{
      margin-top:0 !important;
    }
  }
}

.product-view {
  .products-list {
    padding: 0 rem(20);
    //margin-bottom: rem(-20);
    //margin-top: rem(30);

    > ul {
      > li {
        width: 100%;
        > a {
          color: #000;
          background-color: #ffcc00;
          border: 1px solid #ffcc00;
          border-bottom-color: transparent;

          .caret {
            margin-top: rem(10);
            display: inline-block;
          }

          &:hover,
          &:focus {
            color: #fff;
            background-color: darken(#ffcc00, 6.5%);
            border: 1px solid darken(#ffcc00, 6.5%);
            border-bottom-color: transparent;
            cursor: default;
          }
        }

        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }
}

@media(min-width: $screen-sm-min) {
  .product-view {
    .products-list {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: 0;
      margin-top: 0;

      > ul {
        > li {
          width: inherit;

          .dropdown-menu {
            width: inherit;
          }
        }
      }
    }
  }
}


.btn-stockists {
  display: none;
  float: right;
  background-color: #DADADA;
  border: 0 none;
  border-radius: 15px;
  color: #575656;
  font-weight: bold;
  margin-top: -10px;
  -webkit-box-shadow: 0px 0px 30px 1px rgba(135,135,135,0.4);
  -moz-box-shadow: 0px 0px 30px 1px rgba(135,135,135,0.4);
  box-shadow: 0px 0px 30px 1px rgba(135,135,135,0.4);

  span {
    $size: 20px;

    display: inline-block;
    width: $size;
    height: $size;
    float: left;
    margin-right: 4px;
    border: 2px solid #fff;
    border-radius: $size;
    position: relative;
    left: -5px;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background-color: darken(#DADADA, 10%);
    color: #575656;
    -webkit-box-shadow: 0px 0px 30px 1px rgba(180,180,180,0.4);
    -moz-box-shadow: 0px 0px 30px 1px rgba(180,180,180,0.4);
    box-shadow: 0px 0px 30px 1px rgba(180,180,180,0.4);
  }

  .node-type-products & {
    display: inline-block;
  }
}
