.heading{
  background-color:#e8e8e8;
  text-align:center;
  padding-top:rem(20);
  padding-bottom:rem(20);

  h1{
    margin:0;
    padding:0;
    @include font-family-1(300,24);
    text-transform:uppercase;
    @media(max-width:768px){
      font-size:rem(20);
    }
  }
}

@media (min-width: $screen-sm-min) {
  .product-view {
    .heading {
      .products-list {
        margin-bottom: rem(-20);
      }
    }
  }
}
