#user-login{
  .form-item{
    @extend .form-group;
  }
  .description{
    display:none;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"]{
    @extend .form-control;
    @include font-family-1(300,14);    
    resize:none;
    outline:none;
  }
  input[type="submit"]{
    @extend .btn;
    @extend .btn-primary;
    @include font-family-1(300,14); 
    color:#000;   
    text-transform:uppercase;
    outline:none;  
    -webkit-appearance:none;          
  }  
}