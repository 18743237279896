@mixin gradient(){
  background: #333333; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMTAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9yYWRpYWxHcmFkaWVudD4KICA8cmVjdCB4PSItNTAiIHk9Ii01MCIgd2lkdGg9IjEwMSIgaGVpZ2h0PSIxMDEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
  background: -moz-radial-gradient(center, ellipse cover,  #333333 10%, #333333 30%, #000000 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(10%,#333333), color-stop(30%,#333333), color-stop(100%,#000000)); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover,  #333333 10%,#333333 30%,#000000 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover,  #333333 10%,#333333 30%,#000000 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover,  #333333 10%,#333333 30%,#000000 100%); /* IE10+ */
  background: radial-gradient(ellipse at center,  #333333 10%,#333333 30%,#000000 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#000000',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */  
}
@mixin nav-gradient(){
  @include gradient;
}
@mixin footer-gradient(){
  @include gradient;
}
@mixin bar-gradient(){
  @include gradient;  
}
@mixin product-gradient(){
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #B4BCC9), color-stop(0.6, #ECF3FD));
  background-image: -o-linear-gradient(bottom, #B4BCC9 0%, #ECF3FD 60%);
  background-image: -moz-linear-gradient(bottom, #B4BCC9 0%, #ECF3FD 60%);
  background-image: -webkit-linear-gradient(bottom, #B4BCC9 0%, #ECF3FD 60%);
  background-image: -ms-linear-gradient(bottom, #B4BCC9 0%, #ECF3FD 60%);
  background-image: linear-gradient(to bottom, #B4BCC9 0%, #ECF3FD 60%);  
}