#block-views-stockists-list-block{
  h2{
    @include font-family-1(300,24);  
    margin:0;
    padding:0;                         
    text-transform:uppercase;
    padding-bottom:rem(10);
    margin-bottom:rem(10);
  }  
  h3{
    @include font-family-1(500,16);  
    margin-bottom:0;  
  } 
}
