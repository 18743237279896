.view-stockists-list{
  .views-field{
    @include font-family-1(300,14); 
    &.views-field-field-telephone,
    &.views-field-field-email,
    &.views-field-field-website{
      .views-label{
        @include font-family-1(500,14); 
        display:inline;
      } 
      .field-content{
        display:inline;
      }         
    }   
    &.views-field-title{
      @include font-family-1(500,14);             
    }
  }
  .views-row{
    margin-bottom:rem(20);
  }
}