.yam {
  .nav {
    position: relative;
  }
}

nav {
  &.navbar {
    &.yamm {
      z-index: 900;
    }
  }
}


.navbar-nav {
  .yamm-fw {

    &.open {
      .dropdown-menu-wrapper {
        display: block;

        .dropdown-menu {
          display: block !important;
        }
      }
    }

    .dropdown-menu-wrapper {
      display: none;
      background-color: white;
      position: absolute;
      height: 300px;
      min-height: 300px;
      z-index: 100;
      -webkit-box-shadow: 0px 5px 20px -1px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 5px 20px -1px rgba(0,0,0,0.75);
      box-shadow: 0px 5px 20px -1px rgba(0,0,0,0.75);
      left: 0;
      right: 0;
      top: 100%;

      .dropdown-menu {
        width: 100%;
        //position: relative;
        height: 300px;

        .row {
        }
      }

      > li {

        > .row {
          > .col {

          }
        }
      }

      a {
        color: $almost-black;
        font-family: $font-family-open-sans;
        text-transform: none;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
        }

        > li.product-group-nav {
          border-bottom: 1px solid $gray-lighter;

          > a {
            text-transform: uppercase;
            font-family: $font-family-headings;
            font-weight: bold;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 25px;
            padding-left: 25px;
            text-decoration: underline;
            position: relative;
            z-index: 100;
          }

          .dropdown-toggle {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding-right: 25px;
            text-align: right;
            z-index: 10;
            margin: 0;

            .fa {
              margin-top: 25px;
            }
          }

          > ul {
            display: none;
            background-color: $gray-lighter;
            padding: 25px 50px;

            > li {
              margin: 12px 0;
              > a {
                color: $gray-light;
                display: block;
                font-size: $font-size-base * 0.9;
                margin-bottom: 8px;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
}

.navbar-nav {
  .yamm-fw {
    &.support-menu {
      .dropdown-menu-wrapper {
        min-height: 0;
        height: auto;
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-nav {
    .yamm-fw {
      &.open {
        .dropdown-menu-wrapper {
          max-height: none;
          height: auto;

          .dropdown-menu {
            max-height: none;
          }
        }
      }

      &.support-menu {

      }

      .dropdown-menu {
        padding: 20px 50px 40px 50px;

        .row {
          margin: 0;
        }

        a {
        }

        ul {

          li {
          }

          > li.product-group-nav {
            border-bottom: 0 none;

            > a {
              text-decoration: none;
              padding-left: 0;
            }

            .dropdown-toggle {
              display: none;
            }

            > ul {
              display: block !important;
              background-color: transparent;
              padding: 0;

              > li {
                margin: 6px 0;
                > a {
                }
              }
            }
          }
        }
      }
    }
  }
}