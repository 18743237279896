.view-product-downloads{
  ul{
    margin:0;
    padding:0;
    margin-top:rem(15);
    li{
      list-style-type: none;
      margin-left:0 !important;
    }
  }
}
