.view-search{
  .view-header{
    ul{
      margin-left:rem(-1);
    }
    a{
      &.dropdown-toggle{
        color: #000;
        background-color: #ccc;
        border: 1px solid #ccc;
        border-bottom-color: transparent;
        cursor: default;
        &:hover,&:focus{
          color: #fff;
          border-bottom-color: transparent;
          cursor: default;
        }
      }
    }
  }
  .views-row{
    h2{
      @include font-family-1(300,20);
      margin-bottom:0;
      a{
        color:#000;
        text-decoration:none;
        &:hover{
          text-decoration:underline;
        }
      }
    }
    span{
      &.field-content{
        @include font-family-1(300,14);
        color:#666;
      }
    }
  }
  .view-content{
    margin-bottom:rem(15);
  }

  &.view-display-id-page_3 {
    .views-row {
      h2 {
        a {
          color: $link-color;
        }
      }
    }
  }
}
