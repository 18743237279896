.view-product-accessories{
  .product-accessories-product{
    @extend .row;
    margin-bottom:rem(15);
    .product-accessories-product-thumbnail{
      @extend .col-sm-2;
      img{
        display:block;
        border:solid rem(1) #ccc;
        max-width:100% !important;
        min-width:100% !important;
        height:auto !important;
      }
    }
    .product-accessories-product-detail{
      @extend .col-sm-10;
      h3{
        font-size: rem(18);
        a{
          color:#000;
          &:hover{
            color:#000;
          }
        }
      }
    }
  }
}
