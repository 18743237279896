.share{
  background-color:#000;
  border-top:solid rem(1) #64686b;
  padding-top:rem(10);
  padding-bottom:rem(6);
  text-align:center;
  .addthis_sharing_toolbox{
    display:inline-block;
  }
}
