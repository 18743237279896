.page-stockists {
  #set-location-form {
    > div {
      background-color: lighten($gray-lighter, 5%);
      padding: 10px 20px;

      .form-item-street-address {
        max-width: 80%;

        .form-text {
          width: 200px;
        }
      }

      .form-submit {
        display: none;
      }

      a {
        color: $gray-dark;
        font-style: italic;

        span {
          font-size: $font-size-base * 1.2;
        }

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  #block-views-stockists-v2-block-1 {
    .views-row {
      padding: 10px;
      font-size: $font-size-base * 0.8;

      background-color: lighten($gray-lighter, 5%);

      &.views-row-odd {
        background-color: $gray-lighter;
      }

      &.synced-marker-hover {
        background-color: lighten($brand-yellow, 10%);
      }

      h5 {
        font-size: $font-size-base * 1.2;
        font-family: $font-family-headings;
        text-transform: uppercase;
      }

      .views-field-field-geofield-distance,
      .views-field-field-stockist-groups,
      .views-field-field-email,
      .views-field-field-telephone,
      .views-field-field-website,
      .views-field-field-stockist-type,
      .views-field-field-outlet-type {
        padding: 4px 0;

        .field-content {
          display: inline-block;
        }

        .views-label {
          font-weight: bold;
        }
      }
    }
  }

  .views-exposed-form {
    background-color: $gray-lighter;
    padding: 10px;

    .form-select {
      @extend .form-control;
      height: 30px;
      margin-top: 3px;
    }

    .form-submit {
      //don't extend - appears ot be a bug in compilation that causes everything to shit the bed
      //@extend .btn;
      @extend .btn-primary;
      border-radius: 4px;
      padding: 6px 20px;
      border: 1px solid #999;
      cursor: pointer;
      color: #000;
      font-weight: 300;
      text-transform: uppercase;
      font-size: $font-size-base * 0.9;
      margin-top: 26px;
    }
  }

  .ip-geoloc-map {
    .leaflet-container {
      height: 400px;
    }
  }
}

#block-views-stockists-v2-block-2 {
  .ip-geoloc-map {
    .leaflet-container {
      height: 400px;
    }
  }
}


@media (min-width: $screen-sm-min) {
  .page-stockists {
    .ip-geoloc-map {
      .leaflet-container {
        height: 500px;
      }
    }
  }
}