.background-blue-gradient {
    @include product-gradient;
    border-bottom: 1px solid #dddddd;
    padding-bottom: rem(20);
}

.background-grey-gradient {
    background: #1a1a1a;
    background: -moz-linear-gradient(top, #1a1a1a 60%, #424242 89%, #1a1a1a 100%);
    background: -webkit-linear-gradient(top, #1a1a1a 60%,#424242 89%,#1a1a1a 100%);
    background: linear-gradient(to bottom, #1a1a1a 60%,#424242 89%,#1a1a1a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1a1a', endColorstr='#1a1a1a',GradientType=0 );
}

.background-light-grey {
    background-color: #f6f6f6;
    padding-bottom: rem(20);
}

.background-dark-grey {
    background-color: #333333;
    padding-bottom: rem(20);
}

.background-white {
    background-color: #ffffff;
    padding-bottom: rem(20);
}

.background-black {
    background-color: #000000;
    padding-bottom: rem(20);
}

.no-title {
    margin: rem(30) 0;

    .front & {
        margin: 0;
    }
}
