nav{
  .item-list{
    ul{
      li{
        margin:0;
      }
    }
  }
}
.pagination{
  li{
    a, span{
      @include font-family-1(300,14);
      color:#000;
      &:hover{
        @include font-family-1(300,14);
        color:#000;
      }
      @media(max-width:736px){
        padding:rem(6) !important;
      }
    }
  }
}
