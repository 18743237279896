.paragraphs-item-slideshow {
    padding: rem(20) 0;

    &.white-background {
        background-color: #fff;
    }

    &.black-background {
        background-color: #000;
    }

    &.dark-grey-background {
        background-color: #333;
    }

    &.light-grey-background {
        background-color: #f6f6f6;
    }

    &.blue-gradient-background {
        @include product-gradient;
        border-bottom: 1px solid #dddddd;
    }

    &.grey-gradient-background {
        background: #1a1a1a;
        background: -moz-linear-gradient(top, #1a1a1a 60%, #424242 89%, #1a1a1a 100%);
        background: -webkit-linear-gradient(top, #1a1a1a 60%,#424242 89%,#1a1a1a 100%);
        background: linear-gradient(to bottom, #1a1a1a 60%,#424242 89%,#1a1a1a 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1a1a', endColorstr='#1a1a1a',GradientType=0 );
    }

    .item {
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    .carousel-control.right,
    .carousel-control.left {
        background-image: none;
    }
}

.paragraphs-item-slideshow-with-text {
    img {
        @extend .img-responsive;
    }

    .item {
        text-align: left;
    }
}

@media(min-width: $screen-lg-min) {
    .paragraphs-item-slideshow {
        margin-bottom: rem(20);
    }
}
