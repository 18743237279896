.products-heading{
  padding-top:rem(15);
  padding-bottom:rem(15);
  border-bottom:solid rem(1) #000;

  h1{
    @include font-family-1(300,30);
    margin:0;
    padding:0;
    line-height:1;
    text-transform:uppercase;
    text-align:center;
    @media(max-width:768px){
      font-size:rem(20);
    }
    span{
      @include font-family-1(300,16);
      margin:0;
      padding:0;
      display:block;
      margin-top:rem(10);
      text-transform:uppercase;
      line-height:1.2;
      @media(max-width:768px){
        font-size:rem(14);
      }
    }
  }
}

.product-view,
.node-type-fancy-page {
  .heading {
    position: relative;
  }

  .sub-title {
    font-size: round($font-size-h2 * 0.65);
    margin: rem(40) 0 rem(20) 0;
    border-bottom: 1px solid #aaa;
    padding-bottom: rem(10);

    &.no-line {
      border-bottom: 0 none;
    }

    &.increase-1 {
      font-size: $font-size-h2 * 0.85;
    }

    &.increase-2 {
      font-size: $font-size-h2;
    }

    &.increase-3 {
      font-size: $font-size-h2 * 1.15;
    }

    &.decrease-1 {
      font-size: $font-size-h2 * 0.55;
    }

    &.decrease-2 {
      font-size: $font-size-h2 * 0.40;
    }

    &.decrease-3 {
      font-size: $font-size-h2 * 0.25;
    }
  }

  .background-dark-grey {
    .sub-title {
      color: #fff;
    }

    .column-title {
      color: #fff;
    }
  }
}

@media(min-width: $screen-sm-min) {
  .product-view,
  .node-type-fancy-page {
    h1.page-title {
      padding-left: rem(200);
      padding-right: rem(200);
    }

    .sub-title {
      text-align: left;
    }
  }
}

@media(min-width: $screen-md-min) {
  .product-view,
  .node-type-fancy-page {
    .sub-title {
      margin-bottom: rem(40);
    }
  }
}