.carousel-inner {
    > .item {
        > img {
            max-width: 70%;
        }
    }
}

@media (min-width: $screen-md-min) {
    .carousel-inner {
        > .item {
            > img {
                max-width: 100%;
            }
        }
    }
}