.view-picture-search{
  .view-content{
    @extend .row;
  }
  .views-row{
    @extend .col-sm-3;
    margin-bottom:rem(25);
    position:relative;
    img{
      min-width:100% !important;
      max-width:100% !important;
      height:auto !important;
      display:block;
    }
    .overlay{
      display:none;
      position:absolute;
    }
    a{
      outline:none;
      display:block;
      &:hover{
        .overlay{
          background-image:url('../img/overlay-image.png');
          display:block;
          width:100%;
          text-align:center;
          color:#fff;
          font-size:rem(24);
        }
      }
    }
    h3{
      @include font-family-1(300,18);
      margin:0;
      padding:0;
      text-transform:uppercase;
      padding-top:rem(9);
      padding-bottom:rem(10);
      margin-bottom:rem(10);
      text-align:center;
      a{
        color:#000;
        text-decoration:none;
      }
    }
  }
}
