.images-loader{
  padding-top:rem(30);
  padding-bottom:rem(60);
  text-align: center;
}
.tabs-loader{
  padding-top:rem(30);
  padding-bottom:rem(30);
  text-align: center;
}
.nav-loader{
  text-align:center;
}
