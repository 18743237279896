.webform-client-form-1,
#mc_embed_signup{

  color: $gray-light;

  input[type="email"],
  input[type="text"],
  select{
    width:100%;
  }

  .form-item{
    margin-top:rem(10);
    margin-bottom:0;

    input[type="text"],
    input[type="email"],
    textarea,
    select{
      @extend .form-control;
      font-family: $font-family-open-sans;
      font-weight: 300;
      //padding:rem(10);
      border:0;
      outline:none;
      background-color:#333;
      border:solid rem(1) #565656;
      resize:none;
      color:#fff;
      &.error{
        border:solid rem(1) red;
      }
    }

    &.form-item-captcha-response{
      label{
        display:none;
      }

      .field-prefix,.description{
        display:none;
      }
    }
  }

  .form-actions{
    margin-top:rem(10);
    margin-bottom:0;
    width:100%;
    input[type="submit"]{
      @extend .btn;
      @extend .btn-primary;
      @extend .btn-block;
      @include font-family-1(300,14);
      border:0;
      padding:rem(10);
      color:#000;
      text-transform:uppercase;
      -webkit-appearance:none;
    }
  }

  .indicates-required {
    font-size: $font-size-base;
    font-style: italic;
    font-weight: 300;
  }
}