#slide-carousel{
  .carousel-inner{

    &>.item{
      background-repeat: no-repeat;
      background-position:center center;
      background-size: cover;
      overflow: hidden;
      height: 120px;


      &>a{

        &>img{
          margin-left:auto;
          margin-right:auto;
          max-width: 50%;
          max-height: 100%;
        }
      }
    }
  }
}

@media(min-width: $screen-xs-min) {
  #slide-carousel {
    .carousel-inner {
      & > .item {
        height: 200px;
      }
    }
  }
}

@media(min-width: $screen-sm-min) {
  #slide-carousel {
    .carousel-inner {
      & > .item {
        height: 250px;
      }
    }
  }
}

@media(min-width: $screen-md-min) {
  #slide-carousel {
    .carousel-inner {
      & > .item {
        height: 300px;
      }
    }
  }
}

@media(min-width: $screen-lg-min) {
  #slide-carousel {
    .carousel-inner {
      & > .item {
        height: 340px;
      }
    }
  }
}

@media(min-width: $screen-xl-min) {
  #slide-carousel {
    .carousel-inner {
      & > .item {
        height: 380px;
      }
    }
  }
}

@media(min-width: $screen-xxl-min) {
  #slide-carousel {
    .carousel-inner {
      & > .item {
        height: 420px;
      }
    }
  }
}