#block-views-frontpage-products-block{
  h2{
      @include font-family-1(300,24);
      margin:0;
      padding:0;
      text-transform:uppercase;
      border-bottom:0;
      padding-bottom:rem(10);
      margin-bottom:rem(20);
      text-align:center;
      background-image:url('../img/seperator.png');
      background-repeat:no-repeat;
      background-position:center bottom;
  }
  .views-field-title{
    @include font-family-1(300,18);
    margin:0;
    padding:0;
    text-align:center;
    text-transform:uppercase;
    padding-bottom:rem(10);
    padding-top:rem(10);
    a{
      color:#000;
      text-decoration:none;
    }
  }
  .views-field-field-description{
    @include font-family-1(300,14);
    padding-bottom:rem(15);
    text-align:center;
  }
  .views-field-field-thumbnail{
    position: relative;
    text-align: center;
  }
}
