@mixin font-family-1($weight,$size){
    font-weight:$weight;
    font-size:rem($size);
}
@mixin font-family-2($size){
  font-family:"fontawesome";
  font-size:rem($size);
}
@mixin font-family-3($size){
  font-weight:700;
  font-size:rem($size);
}