#block-views-frontpage-new-product-block{
  @media(max-width:768px){
    padding-top:rem(30);
  }
  h2{
    @include font-family-1(300,24);
    margin:0;
    padding:0;
    text-transform:uppercase;
    border-bottom:solid rem(1) #000;
    padding-bottom:rem(10);
    margin-bottom:rem(20);
    color:#999;
    line-height:1;
  }
  h3{
    @include font-family-1(300,20);
    margin:0;
    padding:0;
  }
  img{
    max-width:100% !important;
    height:auto !important;
  }
}
