.view-articles{
  .views-row{
    h2{
      @include font-family-1(300,20);
      margin-bottom:0;
      a{
        color:#000;
        text-decoration:none;
        &:hover{
          text-decoration:underline;
        }
      }
    }
    span{
      &.field-content{
        @include font-family-1(300,14);
        color:#666;
      }
    }
  }
  .view-content{
    margin-bottom:rem(15);
  }
}

.node-article {
  .image-wrapper {
    img {
      @extend .img-responsive;
    }
  }
}