.navbar {
    text-transform: uppercase;
    font-family: $font-family-headings;
}

.navbar-nav {
    > li {
        float: left;

        > a {
            .fa {
                margin-left: 4px;
            }
        }
    }

    font-size: round($font-size-base * 1.1);
}

.navbar-collapse {
    > .navbar-nav {
        min-width: 500px;

        > li {
            white-space: nowrap;
        }
    }

    &.language-selection {

        > .navbar-nav {
            margin: 20px 0;
            min-width: 0;
            width: 100%;

            > li {
                width: 100%;
                display: block;
                text-align: center;
            }
        }
    }
}

.navbar-toggle {
    &.search-toggle {
        border: 0 none;
        padding: 0;

        a {
            display: inline-block;
            color: #fff;
            padding: 9px 10px;

            .fa {
                font-size: $font-size-base * 1.3;
            }
        }
    }

    &.language-toggle {
        border: 0 none;
        color: #fff;
    }
}

@media (min-width: $screen-md-min) {
    .navbar {
        .navbar-nav {
            > li {
                margin: 0;

                > a {
                    font-size: round($font-size-base * 0.9);
                    padding-bottom: 15px;
                    padding-top: 55px;
                }
            }
        }

        .navbar-form {
            padding: 0;
            margin-top: 42px;
            margin-bottom: 0;
            padding-bottom: 14px;
            margin-right: 20px;

            .form-control {
                width: 180px;
                margin-left: 10px;
                background-color: $gray;
                color: #fff;
                font-family: $font-family-headings;
                text-transform: uppercase;
            }

            button {
                background-color: transparent;
                border: 0 none;
            }
        }

        .navbar-right {
            background-color: $gray;
            margin-right: -31px;

            .navbar-nav {
                float: right;
                padding: 4px 19px;
            }

            .fa-search {
                color: #fff;
                font-size: $font-size-base * 1.5;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .navbar {
        .navbar-nav {
            >li {
                > a {
                    font-size: $font-size-base * 1;
                }
            }
        }

        .navbar-form {
            margin-right: 140px;

            .form-control {
                width: 200px;
            }
        }
    }
}

@media(min-width: $grid-float-breakpoint) {
    .navbar-collapse.collapse.language-selection {
        height: 0px !important;
        position: static;

        > .navbar-nav {
            margin: 0;
            padding: 0;
            height: 0;
            position: static;
        }

        #google_translate_element {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 2000;
        }

        .nav > li {
            position: static;
        }
    }

    .dropdown-menu-2 {
        li {
            //text-align: center;
        }
    }

    .dropdown-menu-wrapper {
        .dropdown-menu-2 {
            padding-left: 330px;
        }
    }
}