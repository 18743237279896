.navbar-collapse,
.navbar-collapse.in {
  overflow: visible;

  .nav-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  .navbar-nav {
    min-width: 500px;
  }
}

.navbar-brand{
  padding-top:rem(15);
  padding-bottom:rem(15);
}
.dropdown-toggle span{
  float:right;
  font-size:16px;
}
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus{
  background-color:transparent;
}

.nav {
  position: relative;

  > li {
    position: static;
  }
}

.navbar-nav {
  margin-top:0;
  margin-bottom:0;
  padding-top: 17.5px;
  padding-bottom: 17.5px;

  .open {
    .dropdown-menu {
      width: 100%;

      & > li {
        & > a {
          padding:rem(3) rem(15);
        }
      }
    }
  }
}

.dropdown-menu-wrapper {
  overflow:visible;
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: -1px;
  right: -1px;
  top: 100%;
  z-index: 1010;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0px 5px 20px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 20px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 5px 20px -1px rgba(0,0,0,0.75);

  .dropdown-menu {
    position: static;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    border: 0 none;
    box-shadow: none;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }

    > li.product-group-nav {
      border-bottom: 1px solid $gray-lighter;

      > a {
        color: $almost-black;
        text-transform: uppercase;
        font-family: $font-family-headings;
        font-weight: bold;
        display: inline-block;
        margin-bottom: 25px;
        margin-top: 25px;
        padding-left: 25px;
        text-decoration: underline;
        position: relative;
        z-index: 100;
      }

      .dropdown-toggle {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 69px;
        padding-right: 25px;
        text-align: right;
        z-index: 10;
        margin: 0;

        .fa {
          margin-top: 25px;
        }
      }

      > ul {
        display: none;
        background-color: $gray-lighter;
        padding: 25px 50px;

        > li {
          margin: 12px 0;
          > a {
            color: $gray-light;
            display: block;
            font-size: $font-size-base * 0.95;
            margin-bottom: 8px;
            font-weight: 300;
            text-transform: none;
          }
        }
      }
    }
  }
}

@media(min-width:769px){
  .dropdown-toggle span{
    float:none;
    font-size:inherit;
  }
}
@media(max-width:768px){
  .dropdown{
    .dropdown-menu{
      padding-top:0;
      padding-bottom:0;
    }
  }
}

@media(min-width: $screen-md-min) {
  .navbar-collapse,
  .navbar-collapse.in {
    overflow: visible;

    .nav-wrapper {
      width: 100%;
      overflow-x: auto;
    }
  }
}

@media(max-width: $grid-float-breakpoint-max) {
  .navbar-nav {
    .open {
      .dropdown-menu {
        position: absolute;
      }
    }
  }
}

@media(min-width: $grid-float-breakpoint) {
  .navbar-collapse {
    .nav-wrapper {
      float: left;
      width: auto;
    }

    .navbar-nav {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .dropdown-menu-wrapper {
    .dropdown-menu {
      padding: 20px 50px 40px 50px;
    }

    ul {
      > li.product-group-nav {
        border-bottom: 0 none;

        > a {
          text-decoration: none;
          padding-left: 0;
          margin-bottom: 10px;
        }

        .dropdown-toggle {
          display: none;
        }

        > ul {
          display: block !important;
          background-color: transparent;
          padding: 0;

          > li {
            margin: 6px 0;

            a {
              font-size: $font-size-base * 0.9;
            }
          }
        }
      }
    }
  }
}
