.products-accordian{
  margin-top:rem(15);
  ul{
    margin-bottom:rem(5);
    li{
      text-transform:uppercase;
      display:block;
      float:none;
      ul{
        &.dropdown-menu{
          a{
            border:none !important;
          }
        }
      }
      a{
        margin-right:0;
        border-radius:0;
        border: 1px solid #ddd;
      }
      &:last-child{
        a{
          border-bottom: 1px solid #ddd !important;
        }
      }
      &.active{
        a{
          background-color:#ddd !important;
          color:#000 !important;
        }
      }
    }
  }
  a{
    &.dropdown-toggle{
      color: #000;
      background-color: #ffcc00;
      border: 1px solid #ffcc00;
      border-bottom-color: transparent;
      cursor: default;
      &:hover,&:focus{
        color: #fff;
        background-color: darken(#ffcc00, 6.5%);
        border: 1px solid darken(#ffcc00, 6.5%);
        border-bottom-color: transparent;
        cursor: default;
      }
      .caret{
        margin-top:rem(8);
      }
    }
  }
  .panel-heading{
    border-top-right-radius:0;
    border-top-left-radius:0;
    h4{
      font-weight:300;
      font-size:rem(14);
    }
  }
  .panel{
    border-radius:0;
  }
  .panel-collapse{
    padding:rem(15);
    p{
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}
