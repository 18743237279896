.view-product-articles{
  h3{
    font-size: rem(18);    
    margin:0;
    padding:0;
    a{
      color:#000;
      text-decoration:none;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .views-row{
    margin-bottom:rem(15);
  }
}
