.view-frequent-asked-questions{
  .views-row{
    h2{
      @include font-family-1(300,20);
      margin-bottom:0;
      a{
        color:#000;
        text-decoration:none;
        &:hover{
          text-decoration:underline;
        }
      }
    }
    div{
      &.field-content{
        @include font-family-1(300,14);
        color:#666;
      }
    }
    .views-field-view-node{
      margin-top:rem(10);
      a{
        @include font-family-1(500,14);
        text-decoration:none;
        color:#000;
      }
    }
  }
  .view-content{
    margin-bottom:rem(15);
  }
}