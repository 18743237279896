.field-name-field-downloads {
    @extend .row;

    > .field-items {
        > .field-item {
            @extend .col-xs-6;
            @extend .col-sm-4;
            margin: rem(5) 0;
        }
    }
}