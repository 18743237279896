.advert-block {
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    margin: 10px 0;

    .overlay {
        transition: opacity 400ms;
        display: block;
        opacity: 0.6;
        padding: 0 60px;

        .title-wrapper {
            display: block;
            width: 160px;
            margin: 0 auto;
        }

        .title {
            opacity: 1;
            text-transform: uppercase;
            font-size: $font-size-base * 1.4;
            font-family: $font-family-headings;
            display: table-cell;
            height: 190px;
            vertical-align: middle;
            text-align: center;
            letter-spacing: 2px;
            width: 160px;
        }
    }

    &:hover {
        .overlay {
            opacity: 0.85;
        }
    }

    &:first-child {
        margin-top: 0;
    }
}

@media (min-width: $screen-xs-min) {
    .advert-block {
        .overlay {
            .title {
                height: 280px;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .advert-block {
        .overlay {
            padding: 0 20px;

            .title {
                height: 160px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .advert-block {
        .overlay {
            padding: 0 40px;

            .title {
                height: 200px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .advert-block {
        .overlay {
            padding: 0 60px;

            .title {
                height: 260px;
            }
        }
    }
}