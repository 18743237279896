.logo {
    background: url('../img/dd-logo.svg') 0 0 no-repeat;
    background-size: contain;
    display: inline-block;
    $width: 99px;
    $height: 62px;

    .navbar & {
        $ratio: 1;
        width: round($width * $ratio);
        height: round($height * $ratio);
        margin-left: 12px;
    }
}