#views-exposed-form-articles-page,
#views-exposed-form-frequent-asked-questions-page,
#views-exposed-form-frequently-asked-questions-page,
#views-exposed-form-search-page,
#views-exposed-form-picture-search-page,
#views-exposed-form-search-page-1,
#views-exposed-form-search-page-2,
#views-exposed-form-search-page-3,
#views-exposed-form-product-search-page{
  padding:rem(10);
  background-color:#ccc;
  margin-bottom:rem(20);
  label{
    display:none;
  }
  select{
    @media(max-width:768px){
      margin-top:rem(5);
    }
  }
  .form-item{
    margin-right:rem(10);
    @media(max-width:768px){
      margin-right:0;
    }
  }
  select,
  input[type="text"]{
    @include font-family-1(300,14);
    @extend .form-control;
    outline:none;
    @media(max-width:768px){
      width:100%;
    }
  }
  input[type="submit"],
  input[type="button"],
  .form-submit{
    @include font-family-1(300,12);
    @extend .btn;
    @extend .btn-primary;
    text-transform:uppercase;
    outline:none;
    color:#000;
    margin-top:0;
    -webkit-appearance:none;
    margin-right:rem(10);
    @media(max-width:768px){
      width:100%;
      margin-right:0;
      margin-top:rem(5);
    }
  }
  .views-widget-filter-combine{
    @extend .form-group;
  }
  .views-exposed-form{
    @media(max-width:768px){
      float:none;
    }
  }
  .views-exposed-widget{
    margin:0;
    padding:0;
    @media(max-width:768px){
      width:100%;
    }
  }
  .views-exposed-widgets{
    margin:0;
    padding:0;
  }
}
