.footer{
  position:absolute;
  bottom:0;
  width:100%;
  height:rem($footer-height);
  .footer-top{
    background: #333333;
    @include footer-gradient;
    padding-top:rem(20);
    padding-bottom:rem(20);
    border-top:solid 1px #64686b;
    border-bottom:solid 1px #64686b;
    .column{
      h2{
        @include font-family-1(300,18);
        font-family: $font-family-headings;
        text-transform:uppercase;
        color:#fff;
      }
      a{
        font-weight: 300;
        text-decoration:none;
        color:#999;
        &:hover{
            color:#ccc;
        }
      }
      p{
        font-weight: 300;
        color:#999;
      }
      .content{
        ul{
          margin:0;
          padding:0;
          li{
            list-style-type:none;
            margin-left:0;
            &.leaf{
              list-style-image:none;
              padding:0;
            }
          }
          &.menu{
            li{
              margin:0;
            }
          }
        }
      }
    }
  }
  .footer-bottom{
    background-color:#000;
    padding-top:rem(10);
    padding-bottom:rem(10);
    p{
      @include font-family-1(300,14);
      margin-bottom:0 !important;
      color:#666;
    }
  }
}