#views-exposed-form-mini-stockists-map-block{
  padding:rem(10);
  background-color:#f3f3f3;
  margin-bottom:rem(20);
  #edit-field-address-country-wrapper{
    display:none;
  }
  #edit-combine-wrapper{
    width:100%;
    input[type="text"]{
      @include font-family-1(300,14);
      @extend .form-control;
      resize:none;
      outline:none;
      @media(min-width:769px){
        margin-bottom:rem(10);        
      }
    }

  }
  .views-exposed-widget{
    padding:0;
    @media(max-width: 768px){
      width:100%;
    }
  }
  .form-submit{
    margin:0;
  }
  .ajax-progress-throbber {
    display:none;
  }
  input[type="submit"]{
    @extend .btn;
    @extend .btn-primary;
    @include font-family-1(300,12);
    text-transform:uppercase;
    position:relative;
    top:rem(1);
    outline:none;
    margin-right:rem(5);
    color:#000;
    -webkit-appearance:none;
    @media(max-width:768px){
      display:block;
      width:100%;
      margin-top:rem(5);
    }
  }
}
