.main{
  h1{
    @include font-family-1(300,24);
    text-align:center;
    border:0;
    background-image:url('../img/seperator.png');
    background-repeat:no-repeat;
    background-position:center bottom;
    padding-bottom:rem(10);
    margin:0;
    margin-bottom:rem(20);
    text-transform:uppercase;
  }
  p{
    padding:0;
    margin:0;
    @include font-family-1(300,14);
    margin-bottom:rem(20);
    &:last-child{
      margin-bottom:0;
    }
  }
  ul {
    @include font-family-1(300, 14);
  }
  &.front, &.page{
    padding-top:rem(30);
    padding-bottom:rem(30);
  }
}

big {
  font-size: $em-base + 2px;
  font-weight: normal;
}

.related-articles {
  .views-row {
    margin-bottom: rem(10);

    a {
      font-size: 14px;
    }
  }
}