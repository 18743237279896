#block-block-7{
  text-align:center;
  h2{
    @include font-family-1(300,20);
    margin:0;
    padding:0;
    text-transform:uppercase;
    color:#ccc;
    display:inline-block;
    margin-top:rem(3);
    width:100%;
    a{
      @extend .btn;
      @extend .btn-primary;
      color:#000;
      margin-left:rem(20);
      margin-top:rem(-4);
      @media(max-width:768px){
        width:100%;
        margin-left:0;
        margin-top:0;
        margin-top:rem(10);
      }
    }
  }
}
