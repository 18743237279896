#webform-client-form-707,
#webform-client-form-8110,
#webform-client-form-8126{
  margin:0;
  padding:0;
  .form-item{
    @extend .form-group;
    margin:0;
    padding:0;
    margin-bottom:rem(5);
    &:first-child{
      margin-top:rem(5);
    }
  }
  .form-actions{
    margin:0;
    padding:0;
    margin-top:rem(5);
    margin-bottom:rem(20);
  }
  input[type="text"],
  input[type="email"],
  textarea,
  select{
    @include font-family-1(300,14);
    @extend .form-control;
    resize:none;
  }
  input[type="submit"]{
    @extend .btn;
    @extend .btn-primary;
    @include font-family-1(300,12);
    color:#000;
    text-transform:uppercase;
    outline:none;
    -webkit-appearance:none;
    @media(max-width:768px){
      width:100%;
      display:block;
    }
  }
  .form-item-captcha-response{
    .field-prefix{
      display:block;
      margin-bottom:rem(5);
    }
  }

  .webform-datepicker {
    select {
      width: 100px;
      display: inline-block;
    }
  }
}
